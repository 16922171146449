import {
  ChannelStore,
  ChatStore,
  QuickMsgStore,
  RSocket,
  Sort,
  TicketStore,
  NOTIFICATION_TYPE,
} from '@qbot-chat/qbot-uikit/chat';
import { NotificationManager } from 'react-notifications';
import notificationStore from './NotificationStore';
import { CHAT_CONTEXT_PATH, CHAT_WS_URL } from '../const/AppConst';
import appStore from './AppStore';
import { chatViewStore } from '../view/ChatView';

const rSocket = new RSocket(
  CHAT_WS_URL,
  CHAT_CONTEXT_PATH,
  (error) => {
    let message = error?.response?.data?.message;
    if (error.response?.status == 401) {
      rSocket.run(
        {
          sender: appStore.user,
          ticket: ticketStore,
          channel: channelStore,
          chat: chatStore,
        },
        (rsocket) => {
          quickMsgStore.setRSocketStore(rSocket);
          chatStore.setQuickMsgStore(quickMsgStore);
          ticketDetailChatStore.setRSocketStore(rSocket);
          historyChannelStore.setRSocketStore(rSocket);
          historyChatStore.setRSocketStore(rSocket);
          appStore.chatReady = true;
        },
      );
    } else {
      NotificationManager.error(
        message || `${error.response?.status} ${error.response?.statusText}`,
      );
    }
  },
  (message) => {
    NotificationManager.error(message);
  },
);

rSocket.setNotificationHandler((type, message) => {
  switch (type) {
    case NOTIFICATION_TYPE.DANGER:
      NotificationManager.error(message);
      break;
    case NOTIFICATION_TYPE.SUCCESS:
      NotificationManager.success(message);
      break;
    case NOTIFICATION_TYPE.WARNING:
      NotificationManager.warning(message);
      break;
    default:
      NotificationManager.info(message);
      break;
  }
});

const chatStore = new ChatStore();

const ticketDetailChatStore = new ChatStore();

const ticketStore = new TicketStore();

ticketStore.setOnAssignmentHandler((ticket) => {
  let customer = ticket.customer;
  let play = notificationStore.assignmentSound.play();
  play
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
  notificationStore.notification(
    `${customer.name}의 상담이 배정되었습니다.`,
    '신규 배정',
    () => {
      ticketStore.enterChannel(ticket).then(() => chatStore.setChannel(ticket));
    },
  );
});

const historyChatStore = new ChatStore();

const quickMsgStore = new QuickMsgStore();

ticketStore.setOnCloseHandler((channelId) => {
  chatViewStore.fetchFindNotClosed();
  notificationStore.notification(`${channelId}가 종료되었습니다.`, '상담 종료');
});

ticketStore.setUnAssignedFn((ticket) => {
  notificationStore.notification(`${ticket.id}가 이관되었습니다.`, '상담 이관');
});

let channelStore = new ChannelStore({
  rowsPerPage: 10,
  paginationSize: 3,
  sort: new Sort('createdAt', Sort.SORT_DIRECTION.DESC),
});

let historyChannelStore = new ChannelStore({
  rowsPerPage: 5,
  paginationSize: 3,
  sort: new Sort('lastMsgAt', Sort.SORT_DIRECTION.DESC),
});

export {
  rSocket,
  chatStore,
  channelStore,
  ticketStore,
  ticketDetailChatStore,
  quickMsgStore,
  historyChannelStore,
  historyChatStore,
};
