import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { getAnalytics } from 'firebase/analytics';
import axios from 'axios';

const firebaseConfig = {
  apiKey: 'AIzaSyB_aW3DLGf7mob9-RFMcenIRkDj_ab-j4U',
  authDomain: 'sooni-427100.firebaseapp.com',
  projectId: 'sooni-427100',
  storageBucket: 'sooni-427100.firebasestorage.app',
  messagingSenderId: '827453309298',
  appId: '1:827453309298:web:ca7a86d9b2d794abaf5deb',
  measurementId: 'G-NN1Y8MXVHE',
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export async function handleAllowNotification() {
  const permission = Notification.permission;
  if (permission === 'granted') return await processGranted();
  if (permission === 'denied') return console.log('알람 허용 안됨');
  if (permission === 'default') {
    const status = await Notification.requestPermission();
    if (status === 'denied') return console.log('알람 허용 안됨');
    if (status === 'granted') return await processGranted();
  }
}

async function processGranted() {
  try {
    await registerServiceWorker();
    const token = await retryGetDeviceToken(3); // 최대 3번까지 재시도
    await axios.post('/user/registration-push-token', {
      token: token,
      type: 'WEB',
    });
    return 'granted';
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// getDeviceToken 재시도 로직 추가
async function retryGetDeviceToken(retries) {
  try {
    return await getDeviceToken();
  } catch (error) {
    if (retries === 0) {
      console.error('최대 재시도 횟수 초과:', error);
      throw error;
    } else {
      console.warn(`getDeviceToken 재시도 중... 남은 횟수: ${retries}`);
      return retryGetDeviceToken(retries - 1);
    }
  }
}

async function getDeviceToken() {
  // 권한이 허용된 후에 토큰을 가져옴
  const token = await getToken(messaging, {
    vapidKey:
      'BLSGE7NL9LfQklYmdMIcAizC5DdoAQt6b-W-hUMdexeO68ynaCsUDvGDbYoW6tAlhKehX8CqeqXSfNYZ1xs9z8Q',
  });
  return token;
}

async function registerServiceWorker() {
  try {
    const registration = await navigator.serviceWorker.register(
      'firebase-messaging-sw.js',
    );
    console.log('Service Worker 등록 성공:');
  } catch (error) {
    console.log('Service Worker 등록 실패:', error);
  }
}

export async function unRegisterServiceWorker() {
  // 서비스 워커 등록 해제
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) await registration.unregister();
    } catch (error) {
      console.error('서비스 워커 해제 중 오류가 발생했습니다:', error);
    }
  }
  // 캐시 삭제
  if ('caches' in window) {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName)),
      );
    } catch (error) {
      console.error('캐시 삭제 중 오류가 발생했습니다:', error);
    }
  }
}

//화면이 켜져있을 경우 알람
onMessage(messaging, (payload) => {
  if (document.visibilityState !== 'visible') return;
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    badge: '/back_logo72.png',
  };
  console.log('for');
  const notification = new Notification(notificationTitle, notificationOptions);

  notification.onclick = () => {
    const TARGET_URL = '/#/main/chat';
    const baseUrl = window.location.origin;
    const newWindow = window.open(baseUrl + TARGET_URL, '_self');
    window.focus();
    if (newWindow) {
      newWindow.focus();
    }
    notification.close();
  };
});
