import React from 'react';
import { observer } from 'mobx-react';
import Styles from './CustomHeader.module.scss';
import Badge from '../badge/Badge';
import { BRAND_COLOR } from '../../const/AppConst';
import { TICKET_STATUS, isEmptyObj } from '@qbot-chat/qbot-uikit';
import { Dropdown, Badge as RBBadge } from 'react-bootstrap';
import { ProgrammingArrow, SmsTracking, TicketExpired } from 'iconsax-react';
import { DropDownButton } from '@qbot-chat/qbot-uikit/chat';
import { closeConfirmDialogStore } from '../dialog/CloseConfirmDialog';
import settingStore from '../../store/SettingStore';
import TicketStatusActiveIcon from '../icon/TicketStatusActiveIcon';
import TicketStatusClosedIcon from '../icon/TicketStatusClosedIcon';
import { ticketStore } from '../../store/AdminChatStore';
import appStore from '../../store/AppStore';
import { Button } from 'antd';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ cursor: 'pointer' }}
  >
    {children}
  </div>
));

@observer
class CustomHeader extends React.Component {
  render() {
    let { store } = this.props;
    let { channel } = store;
    let { properties = null, customer } = channel;
    let { setting } = settingStore;
    const isFinished =
      ticketStore?.ticketMap.get(store?.channelId)?.isFinished ?? false;

    return (
      <div
        className={`${Styles.ChatHeader} ${
          store.channel?.status == TICKET_STATUS.CLOSED ? Styles.Closed : ''
        }`}
      >
        <div className={Styles.MainContainer}>
          {setting?.isCloseableByAgent && (
            <div className={Styles.TicketStatus}>
              {store.channel?.status !== TICKET_STATUS.CLOSED ? (
                <div className={Styles.TicketIcon}>
                  <TicketStatusActiveIcon fill={'var(--qt-primary6)'} />
                </div>
              ) : (
                <div className={Styles.TicketIcon}>
                  <TicketStatusClosedIcon fill={'var(--qt-ch-danger)'} />
                </div>
              )}
            </div>
          )}
          <div className={Styles.NameContainer}>
            {!isEmptyObj(properties) && (
              <div className={Styles.Badge}>
                <Badge
                  color={BRAND_COLOR[properties['brand-id']]}
                  location={properties['branch-name']}
                />
              </div>
            )}
            <div className={Styles.Name}>{customer?.name}</div>
          </div>
          <div className={`d-flex  gap-1`}>
            {store.channel?.status !== TICKET_STATUS.CLOSED &&
              !store.isOpenClose && (
                <>
                  <Dropdown autoClose={true}>
                    {(setting?.isSendableCloseMessage ||
                      setting?.isCloseableByAgent) && (
                      <Dropdown.Toggle as={CustomToggle}>
                        <Button disabled={isFinished}>종료</Button>
                      </Dropdown.Toggle>
                    )}

                    <Dropdown.Menu className={Styles.DropDownMenu}>
                      {setting?.isCloseableByAgent && (
                        <Dropdown.Item>
                          <DropDownButton
                            className={Styles.DropDownButton}
                            onClick={() => {
                              ticketStore.clickClose(channel);
                            }}
                          >
                            <TicketExpired size={20} />
                            종료
                          </DropDownButton>
                        </Dropdown.Item>
                      )}
                      {setting?.isSendableCloseMessage && (
                        <Dropdown.Item>
                          <DropDownButton
                            className={Styles.DropDownButton}
                            onClick={() =>
                              closeConfirmDialogStore.open(store.channel)
                            }
                          >
                            <TicketExpired size={20} />
                            상담 종료 확인하기
                          </DropDownButton>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  {setting?.isTransferableByAgent && (
                    <Dropdown autoClose={true}>
                      <Dropdown.Toggle as={CustomToggle}>
                        <Button>이관</Button>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className={Styles.DropDownMenu}>
                        <Dropdown.Item>
                          <DropDownButton
                            className={Styles.DropDownButton}
                            onClick={() => store.openAgentTransfer()}
                          >
                            <SmsTracking size={20} />
                            티켓 이관 - 상담사
                          </DropDownButton>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <DropDownButton
                            className={Styles.DropDownButton}
                            onClick={() => store.openTeamTransfer()}
                          >
                            <ProgrammingArrow size={20} />
                            티켓 이관 - 팀
                          </DropDownButton>
                        </Dropdown.Item>
                        {/* <DropDownButton
                          onClick={() => transferDialogStore.open()}
                          >
                          <Headphone size={24} />
                          이메일 및 음성이관
                          </DropDownButton> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </>
              )}
          </div>
        </div>
        {channel?.category && (
          <div
            className={`justify-content-start w-100`}
            style={{ fontSize: 10 }}
          >
            <div>
              <RBBadge bg={`secondary`}>종료타입</RBBadge>
              &nbsp;
              {channel.category.closedType === 'CLOSE_DRAFT'
                ? '1차 종결'
                : '점포 확인 후 종결'}
            </div>
            <div>
              <RBBadge bg={`secondary`}>카테고리</RBBadge>
              {appStore.getCategoriesBreadCrumb(channel.category.categoryId)}
            </div>
            <div>
              <RBBadge bg={`secondary`}>관리부서</RBBadge>
              &nbsp;
              {
                appStore.orgs.find(
                  (org) => org.org_id === parseInt(channel.category.orgId),
                )?.full_org_nm
              }
            </div>
          </div>
        )}
      </div>
    );
  }
}

export { CustomHeader };
