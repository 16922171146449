import axios from 'axios';
import { navigate } from '../helper/HistoryHelper';
import appStore from '../store/AppStore';
import { NotificationManager } from 'react-notifications';
import { colorStore } from '@qbot-chat/qbot-uikit';

export const rootStyles = getComputedStyle(document.querySelector(':root'));
export const STATS_COLOR = [
  rootStyles.getPropertyValue(`--bs-warning`),
  rootStyles.getPropertyValue(`--bs-primary`),
  rootStyles.getPropertyValue(`--bs-success`),
  rootStyles.getPropertyValue(`--bs-danger`),
  rootStyles.getPropertyValue(`--bs-info`),
];

export const APP_API_PATH = window.__RUNTIME_CONFIG__.REACT_APP_API_PATH;
export const APP_UPLOAD_PATH = window.__RUNTIME_CONFIG__.REACT_APP_UPLOAD_PATH;
export const APP_CONTEXT_PATH =
  window.__RUNTIME_CONFIG__.REACT_APP_CONTEXT_PATH;
export const CHAT_WS_URL = window.__RUNTIME_CONFIG__.REACT_CHAT_WS_URL;
export const CHAT_API_PATH = window.__RUNTIME_CONFIG__.REACT_CHAT_API_PATH;
export const CHAT_CONTEXT_PATH =
  window.__RUNTIME_CONFIG__.REACT_CHAT_CONTEXT_PATH;
export const REACT_APP_PRODUCT_SEARCH_LAMBDA_API =
  window.__RUNTIME_CONFIG__.REACT_APP_PRODUCT_SEARCH_LAMBDA_API;
export const PDS_API_PATH = window.__RUNTIME_CONFIG__.REACT_PDS_API_PATH;
export const PDS_CONTEXT_PATH =
  window.__RUNTIME_CONFIG__.REACT_PDS_CONTEXT_PATH;
export const PHONE_CONTEXT_PATH =
  window.__RUNTIME_CONFIG__.REACT_PHONE_CONTEXT_PATH;
export const FYD_CONTEXT_PATH =
  window.__RUNTIME_CONFIG__.REACT_FYD_CONTEXT_PATH;

axios.defaults.baseURL = APP_CONTEXT_PATH;
axios.defaults.withCredentials = true;
axios.defaults.responseType = 'json';
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    let response = error.response;
    if (!response || !response.data) {
      NotificationManager.error(error.message);
    }
    NotificationManager.error(response.data.message);
    if (response.status === 401) {
      appStore.isLogin = false;
      navigate('/login');
    }
    throw error;
  },
);

/**
 * URL 메뉴 그룹 추출 정규식
 * @type {RegExp}
 */
export const MENU_GROUP_RE = /\/main\/([^\/]+)(?=\/)?/;

export const PUSH_TYPE = {
  ASSIGNMENT: 'ASSIGNMENT',
};
export const TEMPLATE_CONSTANTS = {
  AGENT_NAME: '상담사명',
  TEAM_NAME: '팀명',
  CUSTOMER_NAME: '고객명',
};
export const TEMPLATE_OPTIONS = Object.entries(TEMPLATE_CONSTANTS).map(
  ([k, v]) => ({ id: k, label: v }),
);

const layout = [
  {
    x: 0,
    y: 0,
    w: 6,
    h: 6,
    minW: 3,
    minH: 6,
    maxH: 9,
    i: 'count',
    static: false,
    isBounded: true,
  },
  {
    x: 6,
    y: 0,
    w: 6,
    h: 6,
    minW: 3,
    minH: 6,
    i: 'agent',
    static: false,
    isBounded: true,
  },
  {
    x: 0,
    y: 6,
    w: 3,
    h: 2,
    minW: 3,
    minH: 2,
    i: 'agentTotal',
    static: false,
    isBounded: true,
    isResizable: false,
    resizeHandles: [],
  },
  {
    x: 3,
    y: 6,
    w: 3,
    h: 2,
    minW: 3,
    minH: 2,
    i: 'agentConsulting',
    static: false,
    isBounded: true,
    isResizable: false,
    resizeHandles: [],
  },
  {
    x: 6,
    y: 6,
    w: 3,
    h: 2,
    minW: 3,
    minH: 2,
    i: 'agentClosed',
    static: false,
    isBounded: true,
    isResizable: false,
    resizeHandles: [],
  },
  {
    x: 9,
    y: 6,
    w: 3,
    h: 2,
    minW: 3,
    minH: 2,
    i: 'botClosed',
    static: false,
    isBounded: true,
    isResizable: false,
    resizeHandles: [],
  },
  {
    x: 0,
    y: 8,
    w: 3,
    h: 3,
    i: 'changeRate',
    static: false,
    isBounded: true,
    isResizable: false,
    resizeHandles: [],
  },
  {
    x: 3,
    y: 8,
    w: 3,
    h: 3,
    i: 'agentConsultingTime',
    static: false,
    isBounded: true,
    isResizable: false,
    resizeHandles: [],
  },
  {
    x: 6,
    y: 8,
    w: 3,
    h: 3,
    i: 'respondTime',
    static: false,
    isBounded: true,
    isResizable: false,
    resizeHandles: [],
  },
  {
    x: 9,
    y: 8,
    w: 3,
    h: 3,
    i: 'assignmentWaitTime',
    static: false,
    isBounded: true,
    isResizable: false,
    resizeHandles: [],
  },
  {
    x: 2,
    y: 8,
    w: 2,
    h: 3,
    minW: 1,
    minH: 1,
    i: 'botConsultingTime',
    static: false,
    isBounded: true,
  },
];

export const DEFAULT_REAL_LAYOUTS = {
  lg: layout,
  md: layout,
  sm: layout,
  xs: layout,
  xxs: layout,
};

export const TICKET_DETAIL_TABS = {
  point: '포인트/영수증',
  emoney: 'e-Moeny',
  store: '점포',
  stock: '재고',
  lost: '분실물',
  membership: '멤버십메뉴얼',
  roadshow: '로드쇼',
};

export const TICKET_FIELD_KEYS = {
  /**
   * 유입 채널 ID
   */
  chnlId: 'inflow-id',

  /**
   * 유입 채널 유형
   */
  chnlCd: 'inflow-type',

  /**
   * 유입 챗봇 유형
   */
  cbotCd: 'inflow-code',

  /**
   * 유입 일시
   */
  chnlDts: 'inflow-at',

  /**
   *  유입 점포 ID
   */
  branchId: 'branch-id',

  /**
   * 유입 점포명
   */
  branchNm: 'branch-name',

  /**
   * 고객 상담 평가
   */
  customerRating: 'customer-rating',

  /**
   * 고객 코멘트
   */
  customerComment: 'customer-comment',

  branchCd: 'branch-id',

  /**
   *  유입 브랜드ID
   */
  brandId: 'brand-id',

  /**
   * 유입 브랜드명
   */
  brandNm: 'brand-name',
};

export const QUICK_REPLY_DIVISION = {
  ALL: 'all',
  PERSONAL: 'personal',
};

export const BRAND_COLOR = {
  EM: '#ffe926',
  TR: '#a6dd27',
  NB: '#ffe926',
};

export const EMART_THEMES = {
  EM: {
    '--emart-point-color': '#ffe926',
    '--emart-point-color-darken': '#ead200',
    '--emart-point-color-lighten': '#ffed51',
    '--erody-hood-color': '#fff',
  },
  TR: {
    '--emart-point-color': '#A6DD27',
    '--emart-point-color-darken': '#A6DD27',
    '--emart-point-color-lighten': '#A6DD27',
    '--erody-hood-color': '#fff',
    '--erody-tr-badge': 'display',
  },
};

export const ACCESS_RIGHTS = {
  SUPER: ['SUPER'],
  ADMIN: ['SUPER', 'ADMIN'],
  AGENT_LEADER: ['SUPER', 'ADMIN', 'AGENT_LEADER'],
  AGENT: ['SUPER', 'ADMIN', 'AGENT_LEADER', 'AGENT'],
};

export const MENU = {
  // COMPANY: 'COMPANY',
  // ADMIN: 'ADMIN',

  CHAT: 'CHAT',
  TICKET: 'TICKET',
  PROFILE: 'PROFILE',
  PASSWORD: 'PASSWORD',
  QUICK: 'QUICK',
  ROAD: 'ROAD',
  BRANCH: 'BRANCH',
  DASH: 'DASH',
  REPORT: 'REPORT',
  MONITOR: 'MONITOR',
  TEAM_AGENT: 'TEAM_AGENT',
  AGENT: 'AGENT',
  TEAM: 'TEAM',
  SETTING: 'SETTING',
  BOT_MESSAGE: 'BOT_MESSAGE',
  NOTICE: 'NOTICE',
  GENERAL: 'GENERAL',
  AUTO: 'AUTO',
  ICON: 'ICON',
  TIME_EVENT: 'TIME_EVENT',
  DIRECT_EVENT: 'DIRECT_EVENT',
  BOT: 'BOT',
  WORK_TIME: 'WORK_TIME',
  TICKET_PROPERTY: 'TICKET_PROPERTY',
  RANK: 'RANK',
  SCENARIO: 'SCENARIO',
  OUTBOUND_CALL: 'OUTBOUND_CALL',
  OUTBOUND_SETTING: 'OUTBOUND_SETTING',
  VOICE_RECORD: 'VOICE_RECORD',
  STANDARD_SCENARIO: 'STANDARD_SCENARIO',
  STANDARD_CHAT: 'STANDARD_CHAT',
  ANNOTATION: 'ANNOTATION',
  OPERATION: 'OPERATION',
};

export const COMMON_ROUTER = [MENU.PROFILE, MENU.PASSWORD, MENU.OPERATION];

export const AGENT_STATUS = {
  ACTIVE: {
    label: '온라인',
    color: colorStore.colors.chSuccess,
  },
  OFFLINE: {
    label: '오프라인',
    color: colorStore.colors.coolGray3,
  },
  AWAY: {
    label: '자리비움',
    color: colorStore.colors.chWarning,
  },
};
