import React, { useState } from 'react';
import Styles from './OperationView.module.scss';
import appStore from '../../store/AppStore';
import { USER_TYPE } from '@qbot-chat/qbot-uikit';
import { goBack } from '../../helper/HistoryHelper';
import { Button, Result, Spin } from 'antd';

const OperationView = () => {
  const { type, companyId } = appStore;
  const [loading, setLoading] = useState(true);
  const [isError, setisError] = useState(false);

  if (![USER_TYPE.ADMIN, USER_TYPE.SUPER].includes(type) || companyId !== 2)
    return goBack();

  return (
    <div className={Styles.IframeWrapper}>
      {loading && <Spin className={Styles.Loading} size="large" />}
      {isError ? (
        <Result
          status="warning"
          title="운영 리포트 페이지에 에러가 발생했습니다."
          subTitle={
            <>
              불편을 드려 죄송합니다. 잠시 후 다시 시도해 주세요. <br />
              문제가 지속될 경우 퀀텀에이아이(02-6408-0915)를 통해 문의해 주시기
              바랍니다.
            </>
          }
          extra={
            <Button type="primary" onClick={goBack}>
              뒤로가기
            </Button>
          }
        />
      ) : (
        <iframe
          className={Styles.Iframe}
          src="https://qdocs.quantumaiservice.io/s/46476836-d73f-42ca-b782-273bef302c5a"
          title="운영리포트"
          width="100%"
          height="100%"
          onLoad={() => setLoading(false)}
          onError={() => {
            setLoading(false);
            setisError(true);
          }}
        />
      )}
    </div>
  );
};

export default OperationView;
