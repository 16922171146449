import React, { Component } from 'react';
import Styles from './App.module.scss';
import { Redirect, Route, Router, Switch, withRouter } from 'react-router-dom';
import { HISTORY } from './helper/HistoryHelper';
import 'react-toastify/dist/ReactToastify.css';
import LoginView from './view/LoginView';
import MainViewFrame from './view/MainViewFrame';
import appStore from './store/AppStore';
import { observer } from 'mobx-react';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { ConfirmDialog } from '@qbot-chat/qbot-uikit';
import { ConfigProvider } from 'antd';
import { antTheme } from './util/theme';
import locale from 'antd/locale/ko_KR';
import './firebase';
// const modalRoot = document.getElementById('modal');

class DivWith extends Component {
  //   componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    return <>{this.props.children}</>;
  }
}

const DivWithRouter = withRouter(DivWith);

const NotFound = () => {
  return (
    <div>
      <h1>페이지가 없습니다.</h1>
    </div>
  );
};

@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: true };
  }

  componentDidMount() {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type !== 'navigate') return;
        window.location.href = event.data.url;
      });
    }
  }

  render() {
    return (
      <ConfigProvider theme={antTheme} locale={locale}>
        <div className={`d-flex flex-default ${Styles.Content}`}>
          {appStore.isUpdatingSession ? (
            <div
              className={`flex-default justify-content-center align-items-center h-100 w-100`}
            >
              LOADING...
            </div>
          ) : (
            <Router history={HISTORY}>
              <DivWithRouter>
                <Switch>
                  <Route path="/login" component={LoginView} />
                  <Route path="/main" component={MainViewFrame} />
                  <Redirect exact from="/" to="/login" />
                  <Route component={NotFound} />
                </Switch>
              </DivWithRouter>
            </Router>
          )}
          <ConfirmDialog />
          <NotificationContainer />
        </div>
      </ConfigProvider>
    );
  }
}

export default App;
