import React, { useEffect, useMemo, useState } from 'react';
import { Layout, Menu, Select } from 'antd';
import S from './index.module.scss';
import QtLogo from '../../icon/QtLogo';
import {
  FileTextOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  SettingFilled,
} from '@ant-design/icons';
import Profile from './Profile';
import NavMenuItem from './NavMenuItem';
import { confirmDialogStore, USER_TYPE } from '@qbot-chat/qbot-uikit';
import { NotificationManager } from 'react-notifications';
import appStore from '../../../store/AppStore';
import DropMenu from './NavDropMenu';
import { MAIN_ITEMS, settingItems } from './_config';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import axios from 'axios';
import NoticeCarousel from '../../carousel/NoticeCarousel';
import { HISTORY, navigate } from '../../../helper/HistoryHelper';
import { PATH } from '../../../const/path';

const { Sider } = Layout;

const SUPER_DEFAULT_OPTION = {
  value: 0,
  label: '슈퍼 관리 계정',
};

const SideNav = observer(() => {
  const { isAuthorization, fetchSessionInfo, currentPath, type, companyId } =
    appStore;
  const isAgent = [USER_TYPE.AGENT_LEADER, USER_TYPE.AGENT].includes(type);
  const isAdmin = [USER_TYPE.ADMIN, USER_TYPE.SUPER].includes(type);
  const isSuper = type === USER_TYPE.SUPER;
  const [openKeys, setOpenKeys] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [noticesItems, setNoticesItmes] = useState([]);
  const [isNoticesLodding, setIsNoticesLodding] = useState(true);
  const [companyList, setCompanyList] = useState([SUPER_DEFAULT_OPTION]);
  const path = useMemo(
    () => HISTORY.location.pathname.split('/').slice(0, 3).join('/'),
    [currentPath],
  );
  const onClickLogout = () => {
    confirmDialogStore.openRemove(
      '로그아웃',
      '로그아웃 하시겠습니까 ?',
      () => {
        appStore.fetchLogout();
        NotificationManager.success('로그아웃 되었습니다.');
      },
      null,
      '로그아웃',
    );
  };

  useEffect(() => {
    getNoticeItems();
    getComponyList();
  }, []);

  const getComponyList = () => {
    if (!isSuper) return;
    axios
      .post('/company/list', {})
      .then((res) => {
        const list =
          res.data?.items.map((v) => ({
            value: v.id,
            label: v.companyName,
          })) ?? [];
        setCompanyList([SUPER_DEFAULT_OPTION, ...list]);
      })
      .catch((e) => e);
  };

  const getNoticeItems = () => {
    if (!isAgent) return;
    axios
      .get('/notice/active/list/' + 10)
      .then((res) => {
        setNoticesItmes(res.data);
      })
      .finally(() => {
        setIsNoticesLodding(false);
      });
  };

  const NavItem = MAIN_ITEMS.filter((main) =>
    isAuthorization(main.children.map((v) => v.auth)),
  ).map((main) => {
    const child = main.children.filter((sub) => isAuthorization(sub.auth));

    if (child.length === 1)
      return {
        key: child[0].key,
        label: child[0].label,
        icon: main.icon,
      };

    return {
      ...main,
      children: child,
    };
  });

  return (
    <Sider
      collapsed={collapsed}
      theme="light"
      onCollapse={(value) => setCollapsed(value)}
      collapsedWidth={64}
      width={210}
      className={classNames(S.Nav, collapsed && S.Collapsed)}
    >
      <div className={`${S.NavWrapper} ${S.Wrapper}`}>
        <div className={S.Wrapper}>
          <div className={S.Header}>
            <figure>
              <img
                className={S.SymbolLogo}
                src={
                  appStore?.company?.symbolLogo?.fullUrl ??
                  `/asset/img/qt_logo.png`
                }
                alt="심볼 로고"
                onClick={() => setCollapsed((prev) => !prev)}
              />
              {!collapsed && (
                <>
                  {appStore?.company?.logoFile?.fullUrl ? (
                    <img
                      src={appStore?.company?.logoFile?.fullUrl}
                      alt="로고"
                      className={S.LogoImg}
                    />
                  ) : (
                    <QtLogo width={92} height={13} />
                  )}
                </>
              )}
            </figure>
            {!collapsed && (
              <MenuFoldOutlined
                className={S.CollapsedBtn}
                onClick={() => setCollapsed(true)}
              />
            )}
          </div>
          {!collapsed && isSuper && (
            <Select
              className={S.CompanySelect}
              value={companyId}
              options={companyList}
              onChange={fetchSessionInfo}
              placeholder="회사 선택"
            />
          )}
          <div className={S.ProfileWrapper}>
            <Profile isAgent={isAgent} collapsed={collapsed} />
          </div>

          {/* <NavMenu
            items={mainItems}
            className={S.MainMenu}
            collapsed={collapsed}
          /> */}
          <div className={S.MainMenu}>
            <Menu
              onClick={(v) => {
                appStore.currentPath = v.key;
                navigate(v.key);
              }}
              selectedKeys={[path]}
              className={S.CustomMenu}
              mode="inline"
              items={NavItem}
              inlineIndent={16}
              openKeys={openKeys}
              onOpenChange={(v) => {
                v.length > 1
                  ? setOpenKeys((prev) =>
                      v.filter((item) => !prev.includes(item)),
                    )
                  : setOpenKeys(v);
              }}
            />
          </div>
        </div>

        <div className={S.Footer}>
          {isAgent && (isNoticesLodding || !!noticesItems.length) && (
            <div className={S.NoticeWrapper}>
              <NoticeCarousel
                title="공지사항"
                className={S.Notice}
                items={noticesItems}
                loading={isNoticesLodding}
                onClickItemTitle={(item) =>
                  navigate(PATH.NOTICE.read.name + `/${item.id}`)
                }
              />
            </div>
          )}
          <div className={S.Wrapper}>
            {isAdmin && companyId === 2 && (
              <NavMenuItem
                collapsed={collapsed}
                isBlock
                icon={<FileTextOutlined />}
                label={PATH.OPERATION.main.label}
                isActive={path === PATH.OPERATION.main.name}
                onClick={() => navigate(PATH.OPERATION.main.name)}
              />
            )}
            <DropMenu
              collapsed={collapsed}
              placement={!collapsed ? 'top' : 'rightBottom'}
              items={settingItems}
            >
              <NavMenuItem
                collapsed={collapsed}
                isBlock
                icon={<SettingFilled />}
                label="계정설정"
              />
            </DropMenu>
            <NavMenuItem
              collapsed={collapsed}
              isBlock
              icon={<LogoutOutlined />}
              label="로그아웃"
              onClick={onClickLogout}
            />
          </div>
        </div>
      </div>
    </Sider>
  );
});
export default SideNav;
